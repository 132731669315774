<template>
    <div class="data-item__wrap">
        <!-- 操作按钮 -->
        <div class="btn-groups">
            <div class="l-btns">
                <el-checkbox v-model="data.checked" :disabled="data.pushStatus === 1" @change="handleSel"></el-checkbox>
                <el-radio-group class="btn-group"
                    v-model="active">
                    <el-radio-button v-for="(btn, index) in radios"
                        :key="index"
                        :label="btn.label"
                        border>
                        {{ btn.label }}
                    </el-radio-button>
                </el-radio-group>
                <div v-if="data.pushStatus === 1 || data.pushStatus === 3"
                    :class="['status-wrap', `status-${data.pushStatus}`]">
                    Status:
                    <span v-if="data.pushStatus === 3">Import failed</span>
                    <span v-if="data.pushStatus === 1">Importing...</span>
                </div>
            </div>
            <div class="r-btns">
                <el-button :loading="data.pushStatus === 1" @click="importStore"> Import to store</el-button>
            </div>
        </div>
        <!-- 子模块 -->
        <div class="content-module"
            v-loading="isLoading || data.pushStatus === 1"
            element-loading-spinner="''"
            element-loading-background="rgba(0, 0, 0, 0)">
            <transition-group name="fade">
                <template v-for="(btn, index) in radios">
                    <component v-if="active === btn.label"
                        :key="index"
                        :is="btn.component"
                        :data="data"
                        :categorys="categorys"
                        @do-save="saveImportData">
                    </component>
                </template>
            </transition-group>
        </div>
    </div>
</template>

<script>
import ProductModule from './modules/product'
import DescriptionModule from './modules/description'
import VariantModule from './modules/variant'
import ImagesModule from './modules/images'

export default {
    props: ['data', 'categorys'],
    components: {
        ProductModule,
        DescriptionModule,
        VariantModule,
        ImagesModule
    },
    data () {
        return {
            radios: [
                { label: 'Product', component: ProductModule },
                { label: 'Description', component: DescriptionModule },
                { label: 'Variant', component: VariantModule },
                { label: 'Images', component: ImagesModule }
            ],
            active: 'Product',
            isLoading: false,
            isImporting: false
        }
    },
    methods: {
        // 分模块--保存
        saveImportData ({ data, type }) {
            const params = { type, mspuId: data.spuId }
            if (type === 1) {
                params.productTag = data.tag
                params.changeTitle = data.title
                params.productType = data.type
                params.productCatIds = (data.catIds || []).join(',')
            } else if (type === 2) {
                params.descript = data.description
            } else if (type === 3) {
                params.priceRate = data.priceRate
                params.compareRate = data.comparePriceRate
                params.skuInfos = data.lists
            } else if (type === 4) {
                params.images = data.lists
            }
            this.isLoading = true
            this.$api.updateImportData(params).then(res => {
                this.isLoading = false
                if (res.code === 0) {}
            }).catch(() => {
                this.isLoading = false
            })
        },
        // 选择
        handleSel (val) {
            this.$emit('select-change', val)
        },
        // 导入shopify
        importStore () {
            this.$emit('import-shopify', [this.data.spuId])
        }
    }
}
</script>

<style lang="scss" scoped>
.data-item__wrap {
    border: 1px solid $borderColor;
    border-radius: 2px;
    background: white;
    margin-bottom: 20px;
    box-sizing: border-box;
    .btn-groups {
        padding: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $borderColor;
        .l-btns {
            .el-checkbox {
                margin-right: 30px;
            }
        }
        /deep/ .el-radio-button__inner {
            font-size: 14px;
            font-family: Helvetica;
        }
        /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner{
            color: $mainPrimary;
            background-color: #fff2eb;
            border-color: $mainPrimary;
            box-shadow: -1px 0 0 0 $mainPrimary;
        }
        /deep/ .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
            box-shadow: 0 0 2px 2px transparent;
        }
        .save-btn {
            background: #52C41A;
            color: white;
            border-color: #52C41A;
            &:hover{
                opacity: 0.6;
                transition: all .3s;
            }
        }
    }
    .status-wrap {
        margin-left: 23px;
        display: inline-block;
        position: relative;
        padding: 9px 15px 9px 25px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        border-radius: 32px;
        &::before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 10px;
            bottom: 0;
            margin: auto;
            width: 8px;
            height: 8px;
            border-radius: 8px;
        }
        &.status-3 {
            color: #ff4d4f;
            background: rgba($color: #ff4d4f, $alpha: .1);
            &::before {
                background: #ff4d4f;
            }
        }
        &.status-1 {
            color: #1890FF;
            background: rgba($color: #1890FF, $alpha: .1);
            &::before {
                background: #1890FF;
            }
        }
    }
}
.fade-enter-active {
    transition: opacity .5s;
}
.fade-leave-active {
    transition: opacity .0s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
