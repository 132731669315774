<template>
    <div class="price-range-popover">
        <rap-popover
            ref="popover"
            :placement="placement"
            :width="width"
            :trigger="trigger"
            :visibleArrow="true"
            :visible.sync="focus"
            transition="el-zoom-in-top"
            popper-class="popover-price-range">
            <div slot="reference" class="label-wrap">
                {{ label }} <i :class="focus?'el-icon-caret-top':'el-icon-caret-bottom'"></i>
            </div>
            <div class="content-wrap">
                <el-slider
                    v-model="inputVal"
                    range
                    :min="min"
                    :max="max"
                    :step="10"
                    :show-tooltip="false">
                </el-slider>
                <div class="values">
                    ${{ `${inputVal[0]}` || '' }}-${{ `${inputVal[1]}` || '' }}
                </div>
                <div class="btn-groups">
                    <el-button type="text" @click="doApply">Apply</el-button>
                    <el-button type="text" @click="doClear">clear</el-button>
                </div>
            </div>
        </rap-popover>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        label: String,
        placement: {
            type: String,
            default: 'bottom-end'
        },
        width: {
            type: Number,
            default: 220
        },
        trigger: {
            type: String,
            default: 'hover'
        },
        dataList: Array,
        min: Number,
        max: Number
    },
    data () {
        return {
            inputVal: this.value || [],
            focus: false
        }
    },
    watch: {
        value (nval, oval) {
            if (nval !== oval) {
                this.inputVal = nval
            }
        }
    },
    methods: {
        doApply () {
            this.$emit('input', this.inputVal)
            this.$emit('change', this.inputVal)
            this.$refs.popover && this.$refs.popover.hide()
        },
        doClear () {
            this.inputVal = [0, 0]
            this.doApply()
        }
    }
}
</script>

<style lang="scss">
.price-range-popover{
    display: flex;
    align-items: stretch;
    .label-wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 550;
        color: #1a1a1a;
        position: relative;
        padding: 2px 10px;
        max-height: 300px;
        // overflow: auto;
    }
}
.popover-price-range{
    .content-wrap {
        padding: 0 10px;
        .values{
            padding: 5px 0;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            color: #1a1a1a;
        }
        .btn-groups{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 5px 0;
            .el-button:last-of-type {
                color: #1a1a1a;
            }
        }
    }
}
</style>
