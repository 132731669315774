<template>
    <div class="description">
        <rap-editor v-model="data.description"
            @blur="$emit('do-save', { data: data, type: 2 })">
        </rap-editor>
    </div>
</template>

<script>
export default {
    props: ['data', 'categorys']
}
</script>

<style lang="scss" scoped>
.description {
    padding: 20px;
}
</style>
