var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"variant-wrap"},[_c('el-table',{ref:"multipleTable",staticClass:"common-table variant-table",attrs:{"data":_vm.data.variants,"max-height":340,"tooltip-effect":"dark"},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"50"}}),_c('el-table-column',{attrs:{"label":"Image","width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('rap-image',{staticClass:"img",attrs:{"src":row.image}})]}}])}),_c('el-table-column',{attrs:{"label":"SKU","prop":"sku"}}),_vm._l((_vm.data.attrGroup),function(attr,index){return [_c('el-table-column',{key:index,attrs:{"label":attr.attrName,"min-width":"80","prop":attr.attrName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row[attr.attrName] || '')+" ")]}}],null,true)})]}),_c('el-table-column',{attrs:{"label":"Cost","prop":"costString"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("filterCurrent")(row.priceCurrency))+" "+_vm._s(row.costString || '0')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Shpipping","min-width":"80","prop":"shippingString"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("filterCurrent")(row.priceCurrency))+" "+_vm._s(row.shippingString || 0)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Price","width":"140","prop":"priceString","render-header":_vm.renderHeader},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("filterCurrent")(row.priceCurrency))+" "+_vm._s(row.priceString || '0')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Profit","min-width":"80","prop":"profitString"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("filterCurrent")(row.priceCurrency))+" "+_vm._s(row.profitString || '0')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Compared At Price","width":"180","prop":"comparedAtPriceString","render-header":_vm.renderHeader},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("filterCurrent")(row.priceCurrency))+" "+_vm._s(row.comparedAtPriceString || '0')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Inventory","min-width":"100","prop":"inventory"}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }