<template>
    <div class="images-wrap">
        <el-row :gutter="16">
            <el-col :span="4"
                v-for="(img, index) in data.images"
                :key="index">
                <div :class="['img-box']" @click="chooseImage(img)">
                    <rap-image :src="img.url"></rap-image>
                    <transition name="el-fade-in">
                        <div v-if="img.checkFlag === 1" class="status">
                            <i class="el-icon-check"></i>
                        </div>
                    </transition>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: ['data', 'categorys'],
    methods: {
        chooseImage (img) {
            if (!img.checkFlag || `${img.checkFlag}` === '0') {
                this.$set(img, 'checkFlag', 1)
            } else {
                const sels = this.data.images.filter(p => `${p.checkFlag}` === '1')
                if (sels.length > 1) {
                    this.$set(img, 'checkFlag', 0)
                }
            }
            const lists = this.data.images.map(p => {
                return {
                    mimageId: p.id,
                    checkFlag: p.checkFlag
                }
            })
            this.$emit('do-save', { data: { spuId: this.data.spuId, lists }, type: 4 })
        }
    }
}
</script>

<style lang="scss" scoped>
.images-wrap {
    padding: 0 20px 20px 20px;
    .img-box {
        height: 220px;
        margin-top: 20px;
        border: 1px solid $borderColor;
        border-radius: 2px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        .status {
            position: absolute;
            top: 0;
            right: 0;
            border-top: 33px solid #4DB519;
            border-left: 33px solid transparent;
            i{
                display: inline-block;
                position: absolute;
                top: -28px;
                right: 3px;
                z-index: 2;
                color: white;
            }
        }
    }
}
</style>
