<template>
    <div class="product-wrap">
        <rap-image class="img" :src="data.imageUrl"></rap-image>
        <div class="content-wrap">
            <!-- 标题 - 按钮 -->
            <div class="title-wrap">
                <div class="label">
                    Original title:
                    <div class="original-btn" @click="jumpDetail">View original product <i class="el-icon-arrow-right"></i></div>
                </div>
                <div class="title">{{ data.originTitle }}</div>
            </div>
            <!-- 供应商 -->
            <div class="supplier-wrap">Supplier：{{ data.supplierName }}</div>
            <el-form :model="data">
                <el-form-item>
                    <rap-input v-model="data.title"
                        label="Change title"
                        clearable
                        @blur="handleBlur">
                    </rap-input>
                </el-form-item>
                <el-form-item>
                    <rap-select v-model="data.catIds"
                        label="Choose Collection"
                        value-key="catId"
                        label-key="nameEn"
                        clearable
                        multiple
                        group
                        :multiple-limit="5"
                        :lists="categorys"
                        @blur="$emit('do-save', { data, type: 1 })">
                    </rap-select>
                </el-form-item>
                <el-row :gutter="15">
                    <el-col :span="12">
                        <el-form-item>
                            <rap-input v-model="data.type"
                                label="Choose type"
                                clearable
                                @blur="$emit('do-save', { data, type: 1 })">
                            </rap-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="tag">
                            <rap-tag-input v-model="data.tag"
                                label="Insert tags here"
                                placeholder="Limit 5"
                                showTag
                                clearable
                                @blur="$emit('do-save', { data, type: 1 })">
                            </rap-tag-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data', 'categorys'],
    methods: {
        handleBlur () {
            this.$emit('do-save', { data: this.data, type: 1 })
        },
        jumpDetail () {
            this.$router.push({
                path: `/product/detail/${this.data.originSkuId}/${this.data.originSpuId}`
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.product-wrap {
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    .img {
        flex-grow: 0;
        flex-shrink: 0;
        width: 218px;
        height: 190px;
        border-radius: 2px;
        margin-right: 28px;
    }
    .content-wrap {
        flex-grow: 1;
        padding-right: 5px;
        .title-wrap {
            .label{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: center;
                color: #000000;
            }
            .title {
                padding: 5px 0;
                line-height: 20px;
            }
            .original-btn {
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: center;
                color: rgba($color: #007aff, $alpha: .9);
                cursor: pointer;
                &:hover{
                    opacity: 0.6;
                    transition: all .3s;
                }
                i{
                    color: rgba($color: #000000, $alpha: 0.65);
                }
            }
        }
        .supplier-wrap {
            margin-bottom: 20px;
        }
    }
}
</style>
