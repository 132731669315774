<template>
    <div class="product-search-bar main-content-wrap">
        <div class="search-conditions">
            <el-input class="product-name"
                v-model="searchForm.productName"
                placeholder="Search Products"
                clearable>
                <el-button slot="append"
                    type="primary"
                    icon="el-icon-search"
                    @click="doSearch">
                </el-button>
            </el-input>
            <!-- 分类 -->
            <div class="category-list" v-if="!type">
                <rap-popover-select label="All brand"
                    v-model="searchForm.brandId"
                    :dataList="brands"
                    @change="doSearch">
                </rap-popover-select>
                <rap-popover-select label="All suppliers"
                    v-model="searchForm.supplierId"
                    :dataList="suppliers"
                    @change="doSearch">
                </rap-popover-select>
                <price-range-popover label="Price range"
                    v-model="searchForm.prices"
                    :max="1000"
                    @change="doSearch">
                </price-range-popover>
            </div>
        </div>
    </div>
</template>

<script>
import PriceRangePopover from '@/views/rap/product/list/components/price-range'
export default {
    props: ['brandId', 'type'],
    components: {
        PriceRangePopover
    },
    data () {
        return {
            searchForm: {
                brandId: '',
                supplierId: '',
                prices: [1, 500],
                catId: ''
            },
            brands: [],
            suppliers: [],
            hotBrands: []
        }
    },
    watch: {
        brandId (nval, oval) {
            if (nval !== oval) {
                this.searchForm.brandId = nval
            }
        }
    },
    mounted () {
        this.reqAllBrands()
        this.reqAllSuppliers()
        this.reqHotBrands()
    },
    methods: {
        // 搜索
        doSearch () {
            this.$emit('doSearch', this.searchForm)
        },
        // 选择热门品牌
        selectHotBrand (item) {
            let value = item.value || ''
            if (this.searchForm.brandId === item.value) {
                value = ''
            }
            this.$set(this.searchForm, 'brandId', value)
            this.doSearch()
        },
        // 请求所有品牌
        reqAllBrands () {
            this.$api.getAllBrands({

            }).then(res => {
                if (res.code === 0) {
                    this.brands = (res.data || []).map(p => {
                        return {
                            label: p.name,
                            value: p.brandId
                        }
                    })
                }
            })
        },
        // 请求热门品牌
        reqHotBrands () {
            this.$api.getHotBrands({
                limit: 20
            }).then(res => {
                if (res.code === 0) {
                    this.hotBrands = (res.data || []).map(p => {
                        return {
                            label: p.name,
                            value: p.brandId
                        }
                    })
                }
            })
        },
        // 请求品牌
        reqAllSuppliers () {
            this.$api.getAllSuppliers({

            }).then(res => {
                if (res.code === 0) {
                    this.suppliers = (res.data || []).map(p => {
                        return {
                            label: p.name,
                            value: p.supplierId
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.product-search-bar{
    background: white;
    border: 1px solid #dedede;
    border-radius: 2px;
    .search-conditions{
        padding: 16px 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .product-name{
            width: 464px;
            /deep/ .el-button{
                background: $mainPrimary;
                color: white;
                width: 42px;
                height: 36px;
                padding-left: 0;
                padding-right: 0;
            }
        }
        .category-list{
            display: flex;
            flex-direction: row;
        }
    }
}
</style>
