<template>
    <div class="store-alert__wrap">
        <div class="alert-box">
            <span>
                <i class="el-icon-error"></i>
                Import to store action will be enabled when a store is assigned.
            </span>
            <span class="close-text" @click="doConnectStore">CONNECT A STORE</span>
        </div>
        <!-- 绑定shopify -->
        <bind-store-dialog v-if="showDialog"
            @success="bindStoreSuccess"
            @close="showDialog=false">
        </bind-store-dialog>
    </div>
</template>

<script>
import BindStoreDialog from './bind-store-dialog'
export default {
    components: {
        BindStoreDialog
    },
    data () {
        return {
            showDialog: false
        }
    },
    methods: {
        doConnectStore () {
            this.showDialog = true
        },
        bindStoreSuccess () {
            this.$emit('upload-data')
        },
        showBindStoreDialog () {
            this.doConnectStore()
        }
    }
}
</script>

<style lang="scss" scoped>
.store-alert__wrap {
    .alert-box {
        width: 100%;
        height: 40px;
        background: #fff1f0;
        border: 1px solid #ffa39e;
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: rgba(0,0,0,0.65);
        i {
            color: #FF4D4F;
            margin: 0 5px 0 0;
        }
        .close-text {
            font-size: 12px;
            cursor: pointer;
            color: #1890FF;
        }
    }
}
</style>
