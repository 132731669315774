<template>
    <el-dialog
        title="Connect Shopify"
        custom-class="bind-store-dialog"
        :visible.sync="visible"
        width="610px"
        center
        :close-on-click-modal="false"
        @close="closeDialog">
        <div class="content-wrap" v-loading="loading">
            <div class="text">You haven't connected to your Shopify address, just enter the address and you're ready to use it</div>
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
                <el-form-item prop="shopifyUrl">
                    <el-input
                        v-model="ruleForm.shopifyUrl"
                        placeholder="your Shopify URL"
                        clearable>
                        <template slot="prepend">https://</template>
                        <template slot="append">.myshopify.com</template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" :loading="loading" @click="doLinkStore">Link Store</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: true,
            ruleForm: {
                shopifyUrl: ''
            },
            rules: {
                shopifyUrl: [{ required: true, message: 'URL cannot be empty', trigger: 'change' }]
            },
            loading: false
        }
    },
    methods: {
        // 关联
        doLinkStore () {
            this.$refs.ruleForm &&
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.reqLinkAccount()
                }
            })
        },
        // 请求绑定
        reqLinkAccount () {
            this.loading = true
            this.$api.bindShopify({
                url: this.ruleForm.shopifyUrl || ''
            }).then(res => {
                this.loading = false
                if (res.code === 0 && res.data) {
                    const data = res.data
                    const path = `${data.url}?client_id=${data.client_id}&grant_options[]=${data.grant_options}&scope=${data.scope}&status=${data.status || ''}&redirect_uri=${window.location.origin}`
                    console.log('path----:', path)
                    window.open(path)
                    this.$emit('success')
                    this.closeDialog()
                } else {
                    this.$notify.error(res.message)
                }
            }).catch(() => {
                this.loading = false
            })
        },
        // 弹窗关闭
        closeDialog () {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss">
.bind-store-dialog {
    /deep/ .el-dialog__header {
        padding-top: 40px;
        .el-dialog__title {
            font-size: 32px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            color: #1a1a1a;
        }
    }
    .content-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .text{
            text-align: center;
            padding: 0 50px;
            opacity: 0.7;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            line-height: 22px;
            color: #1a1a1a;
            margin-bottom: 30px;
        }
        .el-form{
            width: 400px;
        }
        /deep/ .el-input-group__prepend {
            padding: 0 5px 0 10px;
        }
        /deep/ .el-input-group__append {
            width: 40%;
            padding: 5px;
        }
    }
}
</style>
