<template>
    <div class="variant-wrap">
        <el-table ref="multipleTable"
            class="common-table variant-table"
            :data="data.variants"
            :max-height="340"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection"
                width="50">
            </el-table-column>
            <el-table-column label="Image" width="70">
                <template slot-scope="{row}">
                    <rap-image class="img" :src="row.image"></rap-image>
                </template>
            </el-table-column>
            <el-table-column label="SKU" prop="sku"></el-table-column>
            <!-- 多规格展示 -->
            <template v-for="(attr, index) in data.attrGroup">
                <el-table-column :key="index"
                    :label="attr.attrName"
                    min-width="80"
                    :prop="attr.attrName">
                    <template slot-scope="{row}">
                        {{ row[attr.attrName] || '' }}
                    </template>
                </el-table-column>
            </template>
            <el-table-column label="Cost" prop="costString">
                <template slot-scope="{row}">
                    {{ row.priceCurrency | filterCurrent }} {{ row.costString || '0' }}
                </template>
            </el-table-column>
            <el-table-column label="Shpipping" min-width="80" prop="shippingString">
                <template slot-scope="{row}">
                    {{ row.priceCurrency | filterCurrent }} {{ row.shippingString || 0 }}
                </template>
            </el-table-column>
            <el-table-column label="Price" width="140" prop="priceString" :render-header="renderHeader">
                <template slot-scope="{row}">
                    {{ row.priceCurrency | filterCurrent }} {{ row.priceString || '0' }}
                </template>
            </el-table-column>
            <el-table-column label="Profit" min-width="80" prop="profitString">
                <template slot-scope="{row}">
                    {{ row.priceCurrency | filterCurrent }} {{ row.profitString || '0' }}
                </template>
            </el-table-column>
            <el-table-column label="Compared At Price" width="180" prop="comparedAtPriceString" :render-header="renderHeader">
                <template slot-scope="{row}">
                    {{ row.priceCurrency | filterCurrent }} {{ row.comparedAtPriceString || '0' }}
                </template>
            </el-table-column>
            <el-table-column label="Inventory" min-width="100" prop="inventory"></el-table-column>
        </el-table>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    props: ['data', 'categorys'],
    data () {
        return {
            priceString: {
                origin: 0,
                rate: 0,
                show: false
            },
            comparedAtPriceString: {
                origin: 0,
                rate: 0,
                show: false
            },
            initFinished: false
        }
    },
    watch: {
        data: {
            handler (val) {
                this.initFinished = false
                this.$nextTick(() => {
                    this.toggleRowSelection()
                })
                this.priceString.rate = val.priceRate || 0
                this.comparedAtPriceString.rate = val.comparePriceRate || 0
                this.priceString.origin = val.priceRate || 0
                this.comparedAtPriceString.origin = val.comparePriceRate || 0
            },
            immediate: true
        }
    },
    methods: {
        // sku选择操作
        toggleRowSelection () {
            const rows = this.data.variants.filter(p => p.checkFlag === 1)
            this.$refs.multipleTable.clearSelection()
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row)
                })
                this.$nextTick(() => {
                    this.initFinished = true
                })
            }
        },
        // 变体选择
        handleSelectionChange (val) {
            if (this.initFinished) {
                this.data.variants.map(p => {
                    p.checkFlag = val.includes(p) ? 1 : 0
                })
                this.doSaveData()
            }
        },
        // 失焦回调保存
        doSaveData () {
            this.$emit('do-save', { data: {
                spuId: this.data.spuId,
                priceRate: this.priceString.rate,
                comparePriceRate: this.comparedAtPriceString.rate,
                lists: this.data.variants.map(p => {
                    return {
                        price: p.priceString,
                        comparePrice: p.comparedAtPriceString,
                        checkFlag: p.checkFlag,
                        mskuId: p.skuId
                    }
                })
            }, type: 3 })
        },
        // 计算价格
        computedProductPrice (key) {
            this.data.variants.map(p => {
                if (key === 'priceString') {
                    p[key] = this.computedPriceToFixed(p.costString, this[key].rate)
                    p.comparedAtPriceString = this.computedPriceToFixed(p[key], this.comparedAtPriceString.rate)
                } else if (key === 'comparedAtPriceString') {
                    p[key] = this.computedPriceToFixed(p.priceString, this[key].rate)
                }
            })
            this.$nextTick(() => {
                this.doSaveData()
            })
        },
        // 价格处理 保留两位小数
        computedPriceToFixed (price, rate) {
            return (parseInt(Number(price) * (Number(rate) + 100)) / 100).toFixed(2)
        },
        // 价格弹窗维护比率
        renderHeader (h, { column, $index }) {
            const that = this
            return h('span', {}, [
                h('el-popover', {
                    props: {
                        placement: 'bottom',
                        width: '220',
                        trigger: 'click',
                        value: that[column.property].show
                    },
                    on: {
                        show: () => {
                            that[column.property].show = true
                        },
                        hide: () => {
                            that[column.property].show = false
                        }
                    }
                },
                [
                    h('div', 
                        {
                            style: `display: flex; flex-direction: row;`
                        },
                        [
                            h('el-input',
                                {
                                    class: 'el-input-price-rate',
                                    placeholder: '请输入',
                                    style: 'width: 150px;margin-right: 10px;height: 30px;',
                                    props: {
                                        value: that[column.property].origin,
                                        size: 'small'
                                    },
                                    on: {
                                        input: value => {
                                            const num = value ? Number(value) : 0
                                            if (0 <= num && num < 500) {
                                                that[column.property].origin = num
                                            }
                                        }
                                    }
                                },
                                [
                                    h('span', { slot: 'append', style: 'width: 40px;' }, '%')
                                ]
                            ),
                            h('el-button',
                                {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    on: {
                                        click: () => {
                                            that[column.property].rate = that[column.property].origin
                                            that[column.property].show = false
                                            that.computedProductPrice(column.property)
                                        }
                                    }
                                },
                                'Apply'
                            )
                        ]
                    ),
                    h('span', { slot: 'reference' }, [
                        h('span', {}, column.label),
                        h('i', {
                            slot: 'reference',
                            class:`${that[column.property].show?'el-icon-caret-top':'el-icon-caret-bottom'}`,
                            style: `color: ${that[column.property].show?'#FF6C23':'#888888'};`
                        }, '')
                    ]),
                ])
            ])
        }
    }
}
</script>

<style lang="scss" scoped>
.variant-wrap {
    width: 100%;
    .img {
        width: 40px;
        height: 40px;
    }
    .current {
        width: 30px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        font-size: 13px;
    }
    /deep/ .current-input {
        &.el-input--prefix .el-input__inner {
            padding-left: 40px;
        }
    }
    /deep/ .el-input-number {
        .el-input-number__decrease,
        .el-input-number__increase {
            display: none;
        }
        &.el-input-number--medium .el-input__inner {
            padding: 0 10px;
            // width: 80px;
            text-align: left;
        }
    }
    .variant-table {
        /deep/.el-table__body .el-table__row:last-of-type td{
            border-bottom-width: 0;
        }
        /deep/ td {
            padding: 4px 0;
        }
        /deep/ .el-table__body .cell {
            display: flex;
        }
        &::before {
            height: 0;
        }
        /deep/ .el-table-column--selection .cell{
            padding: 0 0 0 14px;
        }
    }
}
</style>

<style lang="scss">
.el-input-price-rate /deep/ .el-input-group__append {
    padding: 0 15px;
}
</style>
