<template>
    <div class="data-list__wrap">
        <div class="btn-group">
            <el-checkbox v-model="allChecked"
                :indeterminate="indeterminate"
                @change="handleAllSel">
                Select All
            </el-checkbox>
            <el-button size="small"
                :disabled="batchDisabled"
                @click="importAllStore">
                Import to store
            </el-button>
            <el-button size="small"
                :disabled="batchDisabled"
                @click="removeAllStore">
                Remove from Import list
            </el-button>
        </div>
        <!-- 列表数据 -->
        <transition-group
            name="list"
            class="list-wrap"
            tag="div"
            v-if="dataList && dataList.length">
            <template v-for="item in dataList">
                <data-item :key="item.spuId"
                    :data="item"
                    :categorys="categoryLists"
                    @select-change="handleSelChange"
                    @import-shopify="pushShopifyProduct">
                </data-item>
            </template>
        </transition-group>
        <rap-error v-else title="Your list is empty"></rap-error>
        <!-- 分页 -->
        <rap-pagination class="page-border"
            border
            :total="total"
            :currentPage="pageNum"
            :pageSize="pageSize"
            @change="pageChange">
        </rap-pagination>
    </div>
</template>

<script>
import DataItem from './data-item'

export default {
    components: {
        DataItem
    },
    data () {
        return {
            searchForm: {},
            allChecked: false,
            indeterminate: false,
            dataList: [],
            categoryLists: [],
            total: 0,
            pageNum: 1,
            pageSize: 10,
            updateTimer: null,
            importALlStatus: null,
            againMaxCount: 10
        }
    },
    computed: {
        batchDisabled () {
            return !this.dataList.filter(p => p.checked).length
        }
    },
    mounted () {
        this.reqDataList()
    },
    methods: {
        doSearch (params) {
            this.searchForm = { ...params }
            this.reqDataList()
        },
        // 请求列表
        reqDataList () {
            this.$api.getImportList({
                productName: this.searchForm.productName,
                pageNo: this.pageNum,
                limit: this.pageSize
            }).then(res => {
                if (res.code === 0) {
                    const data = res.data || {}
                    this.total = data.totalRecords || 0
                    this.categoryLists = data.categoryTree || []
                    this.dataList = (data.rows || [])
                    this.dataList.map((p, index) => {
                        p.imageUrl = p.images && p.images.length && p.images[0].url
                        p.title = p.originTitle || ''
                        if (p.variants) {
                            p.variants.map(v => {
                                v.comparedAtPriceString = this.priceToFixed(v.comparedAtPriceString)
                                v.costString = this.priceToFixed(v.costString)
                                v.priceString = this.priceToFixed(v.priceString)
                                v.profitString = this.priceToFixed(v.profitString)
                                v.shippingString = this.priceToFixed(v.shippingString)
                                // 多规格组合
                                const skuAttrs = v.skuAttrs || []
                                skuAttrs.map(sku => {
                                    const attr = p.attrGroup.find(a => `${a.attrId}` === `${sku.attrId}`)
                                    if (attr) {
                                        this.$set(v, attr.attrName, sku.attrValue)
                                    }
                                })
                            })
                        }
                    })
                    this.handleSelChange()
                    this.timeReloadDataList()
                }
            }).catch(() => {

            })
        },
        // 待导入的数据--定时刷新列表
        timeReloadDataList () {
            const spuIds = (this.dataList.filter(p => `${p.pushStatus}` === '1') || []).map(p => p.spuId)
            if (spuIds.length) {
                if (this.updateTimer) {
                    clearInterval(this.updateTimer)
                }
                let maxCount = 0
                this.updateTimer = setInterval(() => {
                    maxCount++
                    if (this.againMaxCount >= maxCount) {
                        this.reqImportListStatus(spuIds)
                    } else {
                        clearInterval(this.updateTimer)
                    }
                }, 2000)
            }
        },
        // 重新获取导入列表--导入中的数据状态
        reqImportListStatus (spuId) {
            this.$api.queryPushStatus({ spuId: spuId }).then(res => {
                if (res.code === 0) {
                    const lists = Object.keys(res.data).map(key => {
                        return { spuId: key, pushStatus: res.data[key] }
                    })
                    if (!lists.find(p => `${p.pushStatus}` === '1')) {
                        this.reqDataList()
                        clearInterval(this.updateTimer)
                    }
                }
            })
        },
        // 处理价格
        priceToFixed (price) {
            return price ? Number(price).toFixed(2) : 0
        },
        // 分页
        pageChange (data) {
            this.pageNum = data.pageNum
            this.pageSize = data.pageSize
            this.reqDataList()
        },
        // 全选
        handleAllSel (val) {
            this.indeterminate = false
            this.dataList.map(p => {
                if (p.pushStatus !== 1) {
                    this.$set(p, 'checked', val)
                }
            })
        },
        // 单个选
        handleSelChange (val) {
            const checks = this.dataList.filter(p => p.checked) || []
            this.indeterminate = false
            this.allChecked = false
            if (checks.length) {
                this.allChecked = !!(this.dataList.length === checks.length)
                this.indeterminate = !!(this.dataList.length !== checks.length)
            }
        },
        // 批量导入
        importAllStore () {
            const spuIds = []
            this.dataList.map(p => {
                if (p.checked) {
                    spuIds.push(p.spuId)
                }
            })
            this.pushShopifyProduct(spuIds)
        },
        // 导入shopify
        pushShopifyProduct (spuId) {
            this.$store.dispatch('userCenter/GET_USER_SHOPIFYSTORE_TYPE', (status) => {
                if (status === 2) {
                    this.dataList.map(p => {
                        if (spuId.includes(p.spuId)) {
                            p.pushStatus = 1
                        }
                    })
                    this.$api.pushImportList({
                        spuId
                    }).then(res => {
                        if (res.code === 0) {
                            // 更新状态
                            this.reqDataList()
                        }
                    }).catch(() => {})
                } else {
                    this.$emit('bind-shopify-store')
                }
            })
        },
        // 批量移出
        removeAllStore () {
            const spuIds = []
            this.dataList.map(p => {
                if (p.checked) {
                    spuIds.push(p.originSpuId)
                }
            })
            this.$api.removeShopifyList({
                spuId: spuIds
            }).then(res => {
                if (res.code === 0) {
                    this.$notify.success('Remove Success')
                    this.reqDataList()
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.data-list__wrap {
    padding-bottom: 20px;
    .btn-group {
        padding: 15px 0;
        .el-checkbox {
            margin-right: 24px;
        }
        .el-button{
            margin-right: 15px;
            margin-left: 0;
        }
    }
}
.list-enter-active,
.list-leave-active {
    transition: all .5s ease-in-out;
}
.list-enter {
    opacity: 0;
    transform: translateX(100px) perspective(500px);
}
.list-leave-to {
    opacity: 0;
    transform: translateX(-100px) perspective(500px);
}
.list-move {
    transition: all .3s ease-in-out;
}
</style>
