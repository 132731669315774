<template>
    <div class="main-content-wrap import-list">
        <!-- 是否关联shopify store -->
        <transition name="el-fade-in">
            <connect-store-alert v-if="showStore" ref="storeAlert"></connect-store-alert>
        </transition>
        <!-- 查询条件 -->
        <product-search-bar class="search" type="importList" @doSearch="doSearch"></product-search-bar>
        <!-- 列表 -->
        <import-data-list ref="dataList" @bind-shopify-store="bindShopifyStore"></import-data-list>
    </div>
</template>

<script>
import ProductSearchBar from '@/views/rap/components/search-bar/product-search-bar.vue'
import ConnectStoreAlert from '@/views/rap/components/store-alert/store-alert.vue'
import ImportDataList from './components/data-list'
export default {
    components: {
        ProductSearchBar,
        ConnectStoreAlert,
        ImportDataList
    },
    computed: {
        showStore () {
            return this.$store.state.userCenter.bindShopifyStatus && this.$store.state.userCenter.bindShopifyStatus === 1
        }
    },
    methods: {
        doSearch (params) {
            this.$refs.dataList && this.$refs.dataList.doSearch(params)
        },
        bindShopifyStore () {
            this.$refs.storeAlert && this.$refs.storeAlert.showBindStoreDialog()
        }
    }
}
</script>

<style lang="scss" scoped>
.import-list {
    padding-top: 25px;
    .store-alert {
        margin-bottom: 15px;
        height: 40px;
        line-height: 20px;
        border: 1px solid #ffa39e;
        border-radius: 2px;
        /deep/ .el-alert__content {
            color: rgba(0,0,0,0.65);
        }
        /deep/.el-alert__closebtn{
            color: #1890FF;
        }
    }
}
</style>
